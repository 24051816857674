export const GET_DATA = 'GET_DATA';
export const UPDATE_DATA = 'UPDATE_DATA';
export const ADD_DATA = 'ADD_DATA';

export const LOG_IN_SUCCESS = 'LOG_IN_SUCCESS';
export const LOG_OUT = 'LOG_OUT';
export const UPDATE_DRAFT = 'UPDATE_DRAFT';


export const UPDATE_GLOBAL_MAIN = 'UPDATE_GLOBAL_MAIN';
export const UPDATE_GLOBAL_INNER = 'UPDATE_GLOBAL_INNER';
export const UPDATE_ALL_TEXT_COLORS = 'UPDATE_ALL_TEXT_COLORS';

export const ADD_THANKS_BUTTON = 'ADD_THANKS_BUTTON';
export const UPDATE_THANKS_BUTTON = 'UPDATE_THANKS_BUTTON';
export const REMOVE_THANKS_BUTTON = 'REMOVE_THANKS_BUTTON';


// export const SET_USER = "SET_USER";
// export const SET_USER_ACTION = "SET_USER_ACTION";
// export const SET_USER_MESSAGE = "SET_USER_MESSAGE";
