import { TelegramShareButton } from "react-share";

export default {
  obj: {
    templateName: '',
    brand: {
      actualName: '',
      brand_logo: '',
      templateLogo: '',
      templateLogoUpdated: false,
      templateLogoSize: '1',
      bannerPath: '',
      brandStatus: '',
      brand_logo_base64: '',
      createDate: '',
      id: '',
      logoPath: '',
      name: '',
    },
    main: {
      preview: true,
      path: 'main',
      template: '1',
      metaTitle: 'ההמלצה שלך שווה לנו המון',
      metaDesc: 'נשמח לשמוע ממך. נשמח לפרגן.',
      metaPic: '',
      dir: 'rtl',
      logoBackgroundColor: '#c9d884',
      titleColor: '',
      titleFontSize: '1.1',
      titleBackgroundColor: '#adc150',
      centerTitle: true,
      bodyBackgroundColor: '#3f6778',
      defaultTextColor: '#fff',
      defaultInputColor: '#60b2d2',
      sharePic: '',
      footerMainTitle: {
        text: 'תודה על שיתוף הפעולה',
        color: '',
        fontSize: '1.5',
      },
      footerSubTitle: {
        text: 'Powered by Comversation',
        color: '',
        fontSize: '0.75',
      },
    },
    thanks: {
      mainTitle: {
        text: 'תודה רבה. עכשיו זה נהיה מעניין',
        color: '',
        fontSize: '1.5',
      },
      subTitle: {
        text: '',
        color: '',
        fontSize: '1.2',
      },
      shareTitle: {
        text: 'שיתוף של ההמלצה יעזור לעוד לקוחות להגיע אלינו<br>אנחנו נפנק אותך על כל לקוח שיגיע דרכך.<br>שווה!<br>אפשר לשתף בכמה מקומות',
        color: '',
        fontSize: '1.2',
      },
      shareButtons: {
        fb: 'true',
        twitter: 'true',
        telegram: 'true',
        whatsapp: 'true',
        linkedin: 'true',
        pinterest: 'true',
        email: 'true',
        sms: 'true',
      },
      buttonsTitle: 'הצטרף לשגרירים ותרוויח',
      buttons: [],
    },
    thanksLayer: {
      showLayer: 'true',
      showThanksLayer: 'false',
      Title: {
        color: '',
        fontSize: '1',
        text: "שים לב! בשביל שנוכל לתגמל אותך כדאי לעדכן את הפרטים האישיים כאן"
      },
      Thanks: {
        color: '',
        fontSize: '1.1',
        text: "תודה! ניצור איתך קשר בהקדם בלי נדר"
      },
      Form: {
        EmailInput: {
          color: '',
          placeholder: 'אימייל',
          fontSize: '1.1'
        },
        PhoneInput: {
          color: '',
          placeholder: 'מספר טלפון',
          fontSize: '1.1'
        },
        Button: {
          bgColor: '#60b2d2',
          color: '',
          fontSize: '1.1',
          text: 'שלח !!!'
        }
      }
    },
    feedback: {
      mainTitle: {
        text: 'נשמח להשתפר',
        color: '',
        fontSize: '1.5',
      },
      subTitle: {
        text: '',
        color: '',
        fontSize: '1.2',
      },
      helloText: {
        text: '',
        color: '',
        fontSize: '1.2',
      },
      emailTitle: {
        text: '',
        color: '',
        fontSize: '1.2',
      },
      feedbackTitle: {
        text: 'מה בדיוק היתה הבעיה ?',
        color: '',
        fontSize: '1.2',
      },
      email: {
        color: '',
        fontSize: '1',
      },
      input: {
        color: '',
        fontSize: '1',
      },
      uploadImageTitle: {
        text: 'תמונה תעזור לנו להבין?',
        color: '',
        fontSize: '1.2',
      },
      buttonText: {
        text: 'שלח',
        color: '',
        fontSize: '1.2',
        backgroundColor: '#60b2d2',
      },
    },
    recommendation: {
      mainTitle: {
        text: 'תודה על הפירגון',
        color: '',
        fontSize: '1.5',
      },
      subTitle: {
        text: '',
        color: '',
        fontSize: '1.2',
      },
      helloText: {
        text: '',
        color: '',
        fontSize: '1.2',
      },
      emailTitle: {
        text: '',
        color: '',
        fontSize: '1.2',
      },
      email: {
        color: '',
        fontSize: '1',
      },
      input: {
        color: '',
        fontSize: '1',
      },
      recommendationTitle: {
        text: ' כתוב למה כדאי להיות לקוח שלנו',
        color: '',
        fontSize: '1.2',
      },
      uploadImageTitle: {
        text: 'תמונה תהפוך המלצה למושלמת',
        color: '',
        fontSize: '1.2',
      },
      buttonText: {
        text: 'לחיצה וסיימנו',
        color: '',
        fontSize: '1.2',
        backgroundColor: '#60b2d2',
      },
    },
    login: {
      text: 'צריך להתחבר עם facebook כדי שנוכל לפרגן חזרה',
      fontSize: '1.5',
      color: '',
    },
    home: {
      templateName: 'תבנית כללית',
      mainTitle: {
        text: ' ההמלצה שלך חשובה לנו ',
        color: '',
        fontSize: '1.5',
      },
      subTitle: {
        text: 'וגם לחברים שלך... לא תפרגן?',
        color: '',
        fontSize: '1.2',
      },
      feedback: {
        titleText: 'להעביר ביקורת',
        titleColor: '',
        titleFontSize: '1.5',
        buttonImage: '',
        buttonText: 'טקסט',
        buttonTextColor: '',
        buttonFontSize: '1.5',
        titleTextFontSize: '1',
        buttonImageSize: '1',
        buttonColor: '#60b2d2',
        selectedImageOption: 'image',
      },
      recommendation: {
        titleText: 'לפרגן מכל הלב',
        titleColor: '',
        titleFontSize: '1.5',
        titleTextFontSize: '1',
        buttonImage: '',
        buttonText: 'טקסט',
        buttonTextColor: '#60b2d2',
        buttonColor: '',
        buttonFontSize: '1.5',
        buttonImageSize: '1',
        selectedImageOption: 'image',
      },
    },
  },
  session: !!sessionStorage.jwt,
  api: '',
};
