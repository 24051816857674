import { createStore, applyMiddleware, compose } from 'redux';
import { connectRouter, routerMiddleware } from 'connected-react-router';
import thunk from 'redux-thunk';
import { createHashHistory } from 'history';
import rootReducer from './reducers';
import {
  saveToLocalStorage,
  loadFromLocalStorage,
} from './shared/localStorage';

export const history = createHashHistory();

const enhancers = [];
const middleware = [thunk, routerMiddleware(history)];

if (process.env.NODE_ENV === 'development') {
  const devToolsExtension = window.__REDUX_DEVTOOLS_EXTENSION__;

  if (typeof devToolsExtension === 'function') {
    enhancers.push(devToolsExtension());
  }
}

const persistedState = loadFromLocalStorage();

const composedEnhancers = compose(
  applyMiddleware(...middleware),
  // ...enhancers
);

const store = createStore(
  connectRouter(history)(rootReducer),
  persistedState,
  composedEnhancers,
);

store.subscribe(() => saveToLocalStorage(store.getState()));

export default store;
