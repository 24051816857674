
import * as types from '../actions/types';
import initialState from './initialState';

export default function sessionReducer(state = initialState.session, action) {
  switch (action.type) {
    case types.LOG_IN_SUCCESS:
      return !!sessionStorage.jwt;
    case types.LOG_OUT:
    //   this.props.history.push('/');
      sessionStorage.removeItem('jwt');

      //   BrowserRouter.push('/');
      return !!sessionStorage.jwt;
    default:
      return state;
  }
}
