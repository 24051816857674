

import 'react-app-polyfill/ie9'; // For IE 9-11 support
import 'react-app-polyfill/ie11'; // For IE 11 support
import './polyfill';
import React from 'react';
import { render } from 'react-dom';
import { Provider } from 'react-redux';
import { HashRouter } from 'react-router-dom';
import store from './store';

import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';

const target = document.querySelector('#root');


render(
  <Provider store={store}>
    {/* <ConnectedRouter history={history}> */}
    <HashRouter>
      <App />
    </HashRouter>
    {/* </ConnectedRouter> */}
  </Provider>,
  target,
);

// ReactDOM.render(<App />, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
