import React, { Component } from 'react';
import { HashRouter, Route, Switch } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';

// import { renderRoutes } from 'react-router-config';
import Loadable from 'react-loadable';
import './App.scss';


const loading = () => <div className="animated fadeIn pt-3 text-center">Loading...</div>;

// Containers
const DefaultLayout = Loadable({
  loader: () => import('./containers/DefaultLayout'),
  loading,
});

// Pages
const Login = Loadable({
  loader: () => import('./views/Pages/Login'),
  loading,
});

const Page404 = Loadable({
  loader: () => import('./views/Pages/Page404'),
  loading,
});

const Page500 = Loadable({
  loader: () => import('./views/Pages/Page500'),
  loading,
});

class App extends Component {
  render() {
    return (
      <HashRouter>
        <Switch>
          <Route exact path="/login" name="Login Page" component={Login} />
          <Route exact path="/404" name="Page 404" component={Page404} />
          <Route exact path="/500" name="Page 500" component={Page500} />
          <Route path="/" name="Home" component={DefaultLayout} />
        </Switch>
      </HashRouter>
    );
  }
}

// function requireAuth(nextState, replace) {
//   console.log(sessionStorage.jwt);
//   if (!sessionStorage.jwt) {
//     replace({
//       pathname: '/login',
//       state: { nextPathname: nextState.location.pathname },
//     });
//   }
// }
const mapDispatchToProps = dispatch => bindActionCreators(
  {
    // updateData,
  },
  dispatch,
);
export default withRouter(connect(
  null,
  mapDispatchToProps,
)(App));

// export default App;
