export const saveToLocalStorage = (state, name = 'admin_state') => {
  try {
    const serializedState = JSON.stringify(state);

    localStorage.setItem(name, serializedState);
  } catch (e) {
    console.log(e);
  }
};

export const loadFromLocalStorage = (name = 'admin_state') => {
  try {
    const serializedState = localStorage.getItem(name);

    if (serializedState === null) return undefined;
    return JSON.parse(serializedState);
  } catch (e) {
    console.log(e);
    return undefined;
  }
};
