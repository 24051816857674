import {
  GET_DATA,
  UPDATE_DATA,
  ADD_DATA,
  UPDATE_GLOBAL_MAIN,
  UPDATE_GLOBAL_INNER,
  REMOVE_THANKS_BUTTON,
  UPDATE_THANKS_BUTTON,
  ADD_THANKS_BUTTON,
  UPDATE_DRAFT, UPDATE_ALL_TEXT_COLORS,

} from '../actions/types';
import {
  saveToLocalStorage,
  loadFromLocalStorage,
} from '../shared/localStorage';

const init = { session: !!sessionStorage.jwt, obj: {} };


function htmlEntities(value) {
  if (typeof (value) === 'boolean') { return value; }
  let newString = value.replace(/"/g, '\'\'');
  newString = value.replace(/\n/g, '<br>');

  return newString;
}

export default function (state = init, action) {
  switch (action.type) {
    case UPDATE_DRAFT: {
      const uniqid = require('uniqid');
      const hash = require('object-hash');

      let oldEditLayout = { ...state.obj, main: { ...state.obj.main, path: 'main' } };

      if (state.obj.id === -1) {
        return { ...state };
      }
      if (state.obj.hash !== undefined) {
        oldEditLayout = (({ hash, ...others }) => ({ ...others }))(oldEditLayout);

        const oldHash = state.obj.hash;
        if (oldHash === hash(oldEditLayout)) { return { ...state }; }
      }

      const pre = loadFromLocalStorage('draft_admin_state');

      const layers = [];

      if (pre === undefined) {
        const initialLayers = [];
        layers.push({
          ...state.obj,
          brand: { ...state.obj.brand, createDate: new Date() },
          id: uniqid(),
        });
        initialLayers.push({ layers, bid: state.obj.brand.id });

        saveToLocalStorage(initialLayers, 'draft_admin_state');
      } else {
        const layersBid = pre.filter(x => x.bid === state.obj.brand.id);
        // check if current bid has drafts

        if (layersBid.length > 0) {
          const objIndex = pre.findIndex(x => x.bid === state.obj.brand.id);

          if (state.obj.id !== undefined) {
            //  console.log(objIndex);
            const draftObj = pre[objIndex].layers.findIndex(x => x.id === state.obj.id);

            // edit draft
            if (draftObj.length !== -1) {
              // check if there was changes for update time
              pre[objIndex].layers[draftObj] = { ...state.obj, id: state.obj.id, updated_at: new Date() };
            }
          } else {
            pre[objIndex].layers.push({ ...state.obj, brand: { ...state.obj.brand, createDate: new Date() }, id: uniqid() });
          }
        } else {
          layers.push({ ...state.obj, brand: { ...state.obj.brand, createDate: new Date() }, id: uniqid() });
          pre.push({ layers, bid: state.obj.brand.id });
        }
        // console.log(pre);
        saveToLocalStorage(pre, 'draft_admin_state');
      }
      // }

      return { ...state };
    }

    case GET_DATA: {
      return {
        ...state,
      };
    }

    case ADD_DATA: {
      return {
        ...state,
        obj: {
          ...action.payload,
        },
      };
    }


    case UPDATE_DATA: {
      const obj = action.payload;
      return {
        obj,
      };
    }

    case ADD_THANKS_BUTTON: {
      return {
        ...state,
        obj: {
          ...state.obj,
          thanks: {
            ...state.obj.thanks,
            buttons: [...state.obj.thanks.buttons, action.payload],
          },
        },
      };
    }

    case REMOVE_THANKS_BUTTON:
      return {
        ...state,
        obj: {
          ...state.obj,
          thanks: {
            ...state.obj.thanks,
            buttons: state.obj.thanks.buttons.filter(button => button.id !== action.payload),
          },
        },
      };

    case UPDATE_THANKS_BUTTON: {
      const id = action.payload[0];
      const inputName = action.payload[1];
      let inputValue = action.payload[2];
      inputValue = htmlEntities(inputValue);
      return {
        ...state,
        obj: {
          ...state.obj,
          thanks: {
            ...state.obj.thanks,
            buttons: state.obj.thanks.buttons.map(button => (button.id === id
              ? (Object.assign({}, button, { [inputName]: typeof inputValue === 'boolean' ? inputValue : inputValue }))
              : button
            )),
          },
        },
      };
    }

    case UPDATE_GLOBAL_MAIN: {
      const inputName = action.payload[0];
      let inputValue = action.payload[1];
      const globalObj = action.payload[2];
      inputValue = htmlEntities(inputValue);

      if (inputName == "centerTitle") {
        inputValue = !state.obj.main.centerTitle
      }

      return {
        ...state,
        obj: {
          ...state.obj,
          [globalObj]: { ...state.obj[globalObj], [inputName]: inputValue },
        },
      };
    }

    case UPDATE_ALL_TEXT_COLORS: {
      const inputName = action.payload[0];
      let inputValue = action.payload[1];
      const globalObj = action.payload[2];

      inputValue = htmlEntities(inputValue);
      // footerMainTitle

      return {
        ...state,
        obj: {
          ...state.obj,
          main: {
            ...state.obj.main,
            titleColor: inputValue,
            defaultTextColor: inputValue,
            footerMainTitle: {
              ...state.obj.main.footerMainTitle,
              color: inputValue,
            },
            footerSubTitle: {
              ...state.obj.main.footerSubTitle,
              color: inputValue,
            },
          },
          thanksLayer: {
            ...state.obj.thanksLayer,
            Title: {
              ...state.obj.thanksLayer.Title,
              color: inputValue,
            },
            Thanks: {
              ...state.obj.thanksLayer.Thanks,
              color: inputValue,
            },
            Form: {
              ...state.obj.thanksLayer.Form,
              EmailInput: {
                ...state.obj.thanksLayer.EmailInput,
                color: inputValue,
              },
              PhoneInput: {
                ...state.obj.thanksLayer.PhoneInput,
                color: inputValue,
              },
              Button: {
                ...state.obj.thanksLayer.Button,
                color: inputValue,
              }
            }
          },
          thanks: {
            ...state.obj.thanks,
            mainTitle: {
              ...state.obj.thanks.mainTitle,
              color: inputValue,
            },
            subTitle: {
              ...state.obj.thanks.subTitle,
              color: inputValue,
            },
            shareTitle: {
              ...state.obj.thanks.shareTitle,
              color: inputValue,
            },
          },
          feedback: {
            ...state.obj.feedback,
            mainTitle: {
              ...state.obj.feedback.mainTitle,
              color: inputValue,
            },
            subTitle: {
              ...state.obj.feedback.subTitle,
              color: inputValue,
            },
            helloText: {
              ...state.obj.feedback.helloText,
              color: inputValue,
            },
            emailTitle: {
              ...state.obj.feedback.emailTitle,
              color: inputValue,
            },
            feedbackTitle: {
              ...state.obj.feedback.feedbackTitle,
              color: inputValue,
            },
            email: {
              ...state.obj.feedback.email,
              color: inputValue,
            },
            input: {
              ...state.obj.feedback.input,
              color: inputValue,
            },
            uploadImageTitle: {
              ...state.obj.feedback.uploadImageTitle,
              color: inputValue,
            },
            buttonText: {
              ...state.obj.feedback.buttonText,
              color: inputValue,
            },
          },
          recommendation: {
            ...state.obj.recommendation,
            mainTitle: {
              ...state.obj.recommendation.mainTitle,
              color: inputValue,
            },
            subTitle: {
              ...state.obj.recommendation.subTitle,
              color: inputValue,
            },
            helloText: {
              ...state.obj.recommendation.helloText,
              color: inputValue,
            },
            emailTitle: {
              ...state.obj.recommendation.emailTitle,
              color: inputValue,
            },
            recommendationTitle: {
              ...state.obj.recommendation.recommendationTitle,
              color: inputValue,
            },
            email: {
              ...state.obj.recommendation.email,
              color: inputValue,
            },
            input: {
              ...state.obj.recommendation.input,
              color: inputValue,
            },
            uploadImageTitle: {
              ...state.obj.recommendation.uploadImageTitle,
              color: inputValue,
            },
            buttonText: {
              ...state.obj.recommendation.buttonText,
              color: inputValue,
            },
          },

          login: {
            ...state.obj.login,
            color: inputValue,
          },
          home: {
            ...state.obj.home,
            mainTitle: {
              ...state.obj.home.mainTitle,
              color: inputValue,
            },
            subTitle: {
              ...state.obj.home.subTitle,
              color: inputValue,
            },
            feedback: {
              ...state.obj.home.feedback,
              titleColor: inputValue,
              buttonTextColor:inputValue
            },
            recommendation: {
              ...state.obj.home.recommendation,
              titleColor: inputValue,
              buttonTextColor:inputValue
            },
          },
        },
      };
    }


    case UPDATE_GLOBAL_INNER: {
      const tmpObject = {};
      const inputName = action.payload[1];
      let inputValue = action.payload[2];
      const globalObj = action.payload[3];
      inputValue = htmlEntities(inputValue);

      if(action.payload[0].indexOf(".") > 0){
        const arrObj = action.payload[0].split(".");
        tmpObject[arrObj[0]] = {
          ...state.obj[globalObj][arrObj[0]],
          [arrObj[1]]: {
            ...state.obj[globalObj][arrObj[0]][arrObj[1]],
            [inputName]: inputValue,
          }
        };
      }else{
        tmpObject[action.payload[0]] = {
          ...state.obj[globalObj][action.payload[0]],
          [inputName]: inputValue,
        }
      }

      console.log('$$$$', {
        ...state,
        obj: {
          ...state.obj,
          [globalObj]: {
            ...state.obj[globalObj],
            ...tmpObject,
          },
        },
      });
      return {
        ...state,
        obj: {
          ...state.obj,
          [globalObj]: {
            ...state.obj[globalObj],
            ...tmpObject,
          },
        },
      };
    }

    default:
      return state;
  }
}
